import common from './common'

const CREATE = '/dashboard/api/v0/casafuneraria/create';
const LIST = '/dashboard/api/v0/casafuneraria/list';
const GET = '/dashboard/api/v0/casafuneraria/get/'
const UPDATE = '/dashboard/api/v0/casafuneraria/update/';
const DESTROY = '/dashboard/api/v0/casafuneraria/destroy/';

export default {
    namespaced: true,
    state: {
        items: [],
    },

    actions: {
        destroy({commit}, item) {
            return axios
                .delete(DESTROY + item.id)
                .then((response) => {
                    console.log("DELETE", response.data)
                    commit('DELETE', item.id)
                    return true;
                }, (e) => {
                    common.logError("DELETE", e)
                    return e.response.data;
                });
        },

        list({commit}) {
            return axios
                .get(LIST)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.count > 0) {
                            commit('SET', response.data.results)
                            return true;
                        }
                    }
                }, (e) => {
                    common.logError("SET", e)
                    return e.response.data;
                });
        },

        create({commit}, item) {
            return axios
                .post(CREATE, item)
                .then((response) => {
                    if (response.status === 201) {
                        console.log("CREATE", response.data)
                        commit('CREATE', response.data)
                        return true;
                    }
                }, (e) => {
                    common.logError("CREATE", e)
                    return e.response.data;
                });
        },

        update({commit}, item) {
            console.log(item)
            return axios
                .patch(UPDATE + item.id, item)
                .then((response) => {
                    console.log("UPDATE", response.data)
                    commit('UPDATE', response.data)
                    return true;
                }, (e) => {
                    common.logError("UPDATE", e)
                    return e.response.data;
                });
        },
    },

    mutations: {

        SET(state, result) {
            state.items = result;
        },
        UPDATE(state, item) {
            let b = state.items.findIndex(items => items.id === item.id);
            state.items.splice(b, 1, item)
        },
        DELETE(state, id) {
            state.items = state.items.filter((item) => item.id !== id)
        },
        CREATE(state, _item) {
            state.items.push(_item);
        },
    },

    getters: {
        items: (state) => state.items,
        getItemById: (state) => (id) => {
            return state.items.find(todo => todo.id === id)
        }
    }

}


