// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const logo = ['642 134', `
<g>
  <g style="fill:#8ddec9;">
    <path d="M348.45,90.0869l-11.84-43.52-.0645-.1923c0-.3409.2129-.5118.6407-.5118h1.2793a.66.66,0,0,1,.705.5762l10.6231,39.68c.042.0859.0859.1279.1289.1279.041,0,.084-.042.127-.1279l10.625-39.68a.6569.6569,0,0,1,.7031-.5762h1.2168a.54.54,0,0,1,.5761.7041l-11.9043,43.52a.6584.6584,0,0,1-.7041.5761h-1.4082A.6579.6579,0,0,1,348.45,90.0869Z"/>
    <path d="M382.7467,89.5752a10.9031,10.9031,0,0,1-4.3515-4.544,14.4586,14.4586,0,0,1-1.5362-6.7842V46.5029a.5656.5656,0,0,1,.64-.64h1.2168a.5659.5659,0,0,1,.64.64v32a10.5491,10.5491,0,0,0,2.72,7.5527,10.36,10.36,0,0,0,14.336,0,10.5493,10.5493,0,0,0,2.7207-7.5527v-32a.5655.5655,0,0,1,.64-.64h1.2159a.5666.5666,0,0,1,.6406.64V78.247a13.01,13.01,0,0,1-3.3926,9.376,11.8971,11.8971,0,0,1-9.0234,3.5527A12.8488,12.8488,0,0,1,382.7467,89.5752Z"/>
    <path d="M440.0524,48.1035H420.02a.2263.2263,0,0,0-.2559.2558V66.8554a.2259.2259,0,0,0,.2559.2559h13.8242a.5666.5666,0,0,1,.6406.64v.96a.5665.5665,0,0,1-.6406.6406H420.02a.2263.2263,0,0,0-.2559.2559v18.56a.2259.2259,0,0,0,.2559.2558h20.0322a.5666.5666,0,0,1,.64.6406v.96a.5654.5654,0,0,1-.64.64H417.9088a.5654.5654,0,0,1-.6406-.64v-43.52a.5658.5658,0,0,1,.6406-.64h22.1436a.5659.5659,0,0,1,.64.64v.96A.5658.5658,0,0,1,440.0524,48.1035Z"/>
    <path d="M455.06,89.5117a2.8385,2.8385,0,0,1-.8-2.0489,2.9193,2.9193,0,0,1,.8-2.1113,2.7518,2.7518,0,0,1,2.0791-.832,2.8465,2.8465,0,0,1,2.9443,2.9433,2.7561,2.7561,0,0,1-.832,2.08,2.9208,2.9208,0,0,1-2.1123.8008A2.7518,2.7518,0,0,1,455.06,89.5117Z"/>
    <path d="M475.3991,88.0078a11.3085,11.3085,0,0,1-3.2-8.416v-5.44a.5655.5655,0,0,1,.64-.64h1.2158a.5662.5662,0,0,1,.6407.64v5.5039a9.1425,9.1425,0,0,0,2.5283,6.72,8.9736,8.9736,0,0,0,6.6875,2.5605,8.7915,8.7915,0,0,0,9.28-9.28V46.5029a.5655.5655,0,0,1,.64-.64h1.2158a.5656.5656,0,0,1,.64.64V79.5918a11.2539,11.2539,0,0,1-3.2315,8.416,13.0621,13.0621,0,0,1-17.0556,0Z"/>
    <path d="M513.3434,88.1035a10.4848,10.4848,0,0,1-3.36-8.128V78.1836a.5666.5666,0,0,1,.6407-.6407h1.0879a.5666.5666,0,0,1,.64.6407v1.6a8.546,8.546,0,0,0,2.752,6.6562,10.5358,10.5358,0,0,0,7.36,2.4961,9.8743,9.8743,0,0,0,6.9766-2.3682A8.2188,8.2188,0,0,0,532,80.2314a8.3954,8.3954,0,0,0-1.12-4.416,11.3752,11.3752,0,0,0-3.3281-3.3926,71.7123,71.7123,0,0,0-6.1758-3.7119,71.0186,71.0186,0,0,1-6.24-3.84,12.1836,12.1836,0,0,1-3.4238-3.68,10.2653,10.2653,0,0,1-1.28-5.3438,9.86,9.86,0,0,1,3.0723-7.7441,12.0124,12.0124,0,0,1,8.3193-2.752q5.6967,0,8.9609,3.1035a10.8249,10.8249,0,0,1,3.2637,8.2246v1.6a.5658.5658,0,0,1-.6406.64h-1.1514a.5651.5651,0,0,1-.64-.64V56.8076a8.8643,8.8643,0,0,0-2.6241-6.6885,9.9935,9.9935,0,0,0-7.2324-2.5273,9.37,9.37,0,0,0-6.5283,2.1435,7.8253,7.8253,0,0,0-2.3672,6.1123,7.81,7.81,0,0,0,1.0234,4.16,10.4,10.4,0,0,0,3.0079,3.039,63.0249,63.0249,0,0,0,5.9521,3.4883,70.7955,70.7955,0,0,1,6.72,4.2559,13.4613,13.4613,0,0,1,3.6485,3.9365,10.0442,10.0442,0,0,1,1.28,5.1836,10.7183,10.7183,0,0,1-3.2646,8.1924q-3.2637,3.0717-8.832,3.0722Q516.7022,91.1757,513.3434,88.1035Z"/>
  </g>
  <g style="fill:#8ddec9;">
    <path d="M605.7468,59.6528a3.4937,3.4937,0,0,0-3.1992,0,2.6556,2.6556,0,0,0-1.0645,1.1016,3.4924,3.4924,0,0,0-.375,1.6484V67.645a3.4925,3.4925,0,0,0,.375,1.6485,2.6559,2.6559,0,0,0,1.0645,1.1015,3.4937,3.4937,0,0,0,3.1992,0,2.6557,2.6557,0,0,0,1.0644-1.1015,3.4926,3.4926,0,0,0,.3755-1.6485V62.4028a3.4925,3.4925,0,0,0-.3755-1.6484A2.6553,2.6553,0,0,0,605.7468,59.6528Z"/>
    <path d="M618.9362,46h-66a2,2,0,0,0-2,2V81.9161a2,2,0,0,0,2,2h66a2,2,0,0,0,2-2V48A2,2,0,0,0,618.9362,46ZM573.0318,64.439a4.8219,4.8219,0,0,1-1.9751,1.914,6.187,6.187,0,0,1-2.9468.6768h-3.8022v6.9394h-3.3716V56.0786H568.11a6.1448,6.1448,0,0,1,2.9468.6826,4.85,4.85,0,0,1,1.9751,1.9258,6.2486,6.2486,0,0,1,0,5.752Zm15.8194,9.53-4.0335-7.1982h-2.0451v7.1982H579.401V56.0659h7.8379a4.9978,4.9978,0,0,1,2.5967.6641,4.5,4.5,0,0,1,1.7348,1.8769,6.7312,6.7312,0,0,1,0,5.6231,4.48,4.48,0,0,1-1.7412,1.8769,4.6355,4.6355,0,0,1-1.1707.4848l4.2777,7.3775Zm22.085-6.4111a7.1411,7.1411,0,0,1-.812,3.4639,5.6851,5.6851,0,0,1-2.295,2.3134,7.5833,7.5833,0,0,1-6.8535,0,5.6854,5.6854,0,0,1-2.2949-2.3134,7.1411,7.1411,0,0,1-.812-3.4639V62.4888a7.1438,7.1438,0,0,1,.812-3.4639,5.6972,5.6972,0,0,1,2.2949-2.3135,7.5916,7.5916,0,0,1,6.8535,0,5.6968,5.6968,0,0,1,2.295,2.3135,7.1438,7.1438,0,0,1,.812,3.4639Z"/>
    <path d="M569.0172,59.604a2.0521,2.0521,0,0,0-1.0766-.2773h-3.8882v4.4541h3.8882a2.0283,2.0283,0,0,0,1.0644-.2764,1.8988,1.8988,0,0,0,.72-.7813,2.5017,2.5017,0,0,0,.2583-1.1572,2.6239,2.6239,0,0,0-.2519-1.1748A1.8484,1.8484,0,0,0,569.0172,59.604Z"/>
    <path d="M587.6359,59.5728a1.6475,1.6475,0,0,0-.9228-.2588h-4.1958v4.2089h4.1958a1.6259,1.6259,0,0,0,.9106-.2587,1.6748,1.6748,0,0,0,.6089-.7383,2.8225,2.8225,0,0,0,.2276-1.1074,2.683,2.683,0,0,0-.2154-1.1075A1.6739,1.6739,0,0,0,587.6359,59.5728Z"/>
  </g>
  <g style="fill:currentColor;">
    <g>
      <path d="M99.835,36.0577l-39-22.5167a12,12,0,0,0-12,0l-39,22.5166a12.0339,12.0339,0,0,0-6,10.3924V91.4833a12.0333,12.0333,0,0,0,6,10.3923l39,22.5167a12,12,0,0,0,12,0l39-22.5167a12.0331,12.0331,0,0,0,6-10.3923V46.45A12.0334,12.0334,0,0,0,99.835,36.0577Zm-2,55.4256a4,4,0,0,1-2,3.4641l-39,22.5167a4.0006,4.0006,0,0,1-4,0l-39-22.5167a4,4,0,0,1-2-3.4641V46.45a4,4,0,0,1,2-3.4642l39-22.5166a4,4,0,0,1,4,0l39,22.5166a4,4,0,0,1,2,3.4642Z"/>
      <path d="M77.8567,82.0046h-2.866a4,4,0,0,0-1.9247.4934L55.7852,91.9833,35.835,80.4648V57.4872l19.95-11.5185,17.2893,9.4549a3.9993,3.9993,0,0,0,1.9192.4906h2.8632a2,2,0,0,0,2-2V51.2024a2,2,0,0,0-1.04-1.7547L59.628,38.9521a8.0391,8.0391,0,0,0-7.8428.09L31.8346,50.56a8.0246,8.0246,0,0,0-4,6.9287v22.976a8,8,0,0,0,4,6.9283l19.95,11.5186a8.0429,8.0429,0,0,0,7.8433.0879l19.19-10.5312a2,2,0,0,0,1.0378-1.7533v-2.71A2,2,0,0,0,77.8567,82.0046Z"/>
    </g>
    <g>
      <path d="M172.58,45.3618a15.0166,15.0166,0,0,0-15,14.9995V77.6387a15,15,0,0,0,30,0V60.3613A15.0166,15.0166,0,0,0,172.58,45.3618Zm7,32.2769a7,7,0,0,1-14,0V60.3613a7,7,0,0,1,14,0Z"/>
      <path d="M135.9138,53.4211a7.01,7.01,0,0,1,7.8681,6.0752.9894.9894,0,0,0,.9843.865h6.03a1.0108,1.0108,0,0,0,.9987-1.0971,15.0182,15.0182,0,0,0-15.7162-13.8837,15.2881,15.2881,0,0,0-14.2441,15.4163V77.2037A15.288,15.288,0,0,0,136.0792,92.62a15.0183,15.0183,0,0,0,15.7162-13.8842,1.0107,1.0107,0,0,0-.9987-1.0971h-6.03a.9894.9894,0,0,0-.9843.865,7.01,7.01,0,0,1-7.8679,6.0757,7.1642,7.1642,0,0,1-6.0789-7.1849V60.6057A7.1638,7.1638,0,0,1,135.9138,53.4211Z"/>
      <path d="M218.7572,72.9277a12.1585,12.1585,0,0,0,7.1843-11.0771V58.1494A12.1494,12.1494,0,0,0,213.7921,46H196.835a1,1,0,0,0-1,1V91a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V74h6.6216l7.9154,17.4138a1,1,0,0,0,.91.5862h6.5911a1,1,0,0,0,.91-1.4138Zm-.8157-11.0771A4.1538,4.1538,0,0,1,213.7926,66h-9.8511V54h9.8511a4.1538,4.1538,0,0,1,4.1489,4.1494Z"/>
      <path d="M260.835,46h-26a1,1,0,0,0-1,1V91a1,1,0,0,0,1,1h26a1,1,0,0,0,1-1V85a1,1,0,0,0-1-1h-19V72h13a1,1,0,0,0,1-1V65a1,1,0,0,0-1-1h-13V54h19a1,1,0,0,0,1-1V47A1,1,0,0,0,260.835,46Z"/>
      <path d="M298.835,46h-6a1,1,0,0,0-1,1V69.6475a7.0066,7.0066,0,1,1-14,0V47a1,1,0,0,0-1-1h-6a1,1,0,0,0-1,1V69.6475a15.0031,15.0031,0,1,0,30,0V47A1,1,0,0,0,298.835,46Z"/>
      <rect x="307.835" y="46" width="8" height="38" rx="1"/>
    </g>
  </g>
</g>
`]

