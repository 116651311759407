import UploadClient from "@uploadcare/upload-client";
import * as imageConversion from "image-conversion";

const baseLink = 'https://ucarecdn.com/';
const clientUploadCare = new UploadClient({publicKey: process.env.VUE_APP_UPLOADCARE});
const uploadCarePublicKey = process.env.VUE_APP_UPLOADCARE;
const RATIO_COMPRESSION = 0.15;


function photoUploader() {
    const fileToUpload = dataURLtoFile(this.currentImages, "image");
    Promise.resolve(clientUploadCare
        .uploadFile(fileToUpload)
        .then(obj => {
            return obj;
        }).then(obj => {
            return obj;
        })
    );
}

function dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
}

export default {

    namespaced: true,
    state: {
        image: null,
        rawImage: null,
    },

    actions: {

        setImage({commit}, img) {
            console.log(img)
            console.log(img)
            console.log(img)
            console.log(img)
            console.log(img)
            console.log(img)
            console.log(img)
            console.log(img)
            console.log(img)
            commit('SET_IMAGE', img)
        },

        upload({commit}, file) {

            imageConversion.compress(file, RATIO_COMPRESSION)
                .then((fileCompressed) => {
                    let blobToFile = new File([fileCompressed], "name");
                    Promise.resolve(clientUploadCare
                        .uploadFile(blobToFile)
                        .then(blobToFile => {
                            return blobToFile;
                        }).then(blobToFile => {
                            commit('SET_IMAGE', blobToFile)
                            commit('SET_RAW_IMAGE', blobToFile)
                            return true;
                        })
                    );
                })

        },

    },

    mutations: {
        SET_RAW_IMAGE(state, blobToFile) {
            state.rawImage = blobToFile;
        },
        SET_IMAGE(state, blobToFile) {
            state.image = {'uuid': blobToFile.uuid, 'src': baseLink + blobToFile.uuid + "/"};
        },
    },

    getters: {
        image: (state) => state.image,
    }
}
