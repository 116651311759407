import common from './common'
const FAQ_CREATE = '/dashboard/api/v0/websites/faq/create';
const FAQ_LIST = '/dashboard/api/v0/websites/faq/list';
const FAQ_UPDATE = '/dashboard/api/v0/websites/faq/update/';
const FAQ_DESTROY = '/dashboard/api/v0/websites/faq/destroy/';

export default {
    namespaced: true,
    state: {
        faqs: [],
    },

    actions: {

        list({commit}) {
            return axios
                .get(FAQ_LIST)
                .then((response) => {
                    if (response.status === 200) {
                        commit('SET', response.data.results)
                        return true;
                    }
                }, (e) => {
                    common.logError("list", e)
                    return e.response.data;
                });
        },

        create({commit}) {
            return axios
                .post(FAQ_CREATE, {})
                .then((response) => {
                    console.log(response)
                    if (response.status === 201) {
                        console.log(response.data)
                        commit('CREATE', response.data)
                        return true;
                    }
                }, (e) => {
                    common.logError("create", e)
                    return e.response.data;
                });
        },

        destroy({commit}, faq) {
            return axios
                .delete(FAQ_DESTROY + faq.id)
                .then((response) => {
                    console.log(response)
                    if (response.status === 204) {
                        commit('REMOVE', faq.id)
                        return true;
                    }
                }, (e) => {
                    common.logError("destroy", e)
                    return e.response.data;
                });
        },

        update({commit}, faq) {
            return axios
                .patch(FAQ_UPDATE + faq.id, faq)
                .then((response) => {
                    console.log(response)
                    return true;
                }, (e) => {
                    common.logError("update", e)
                    return e.response.data;
                });
        },
    },

    mutations: {
        REMOVE(state, id) {
            state.faqs = state.faqs.filter((faq) => faq.id !== id)
        },
        CREATE(state, faq) {
            state.faqs.push(faq)
        },
        SET(state, result) {
            state.faqs = result
        },
    },

    getters: {
        faqs: (state) => state.faqs,
    }
}
