import 'core-js/stable'
import Vue from 'vue'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router'
import {iconsSet as icons} from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'
import axiosApi from 'axios';
import 'vue-select/dist/vue-select.css';
import vSelect from "vue-select";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Geocoder from "@pderas/vue2-geocoder";
import * as VueGoogleMaps from 'vue2-google-maps'
import moment from 'moment-timezone'

let host = window.location.protocol + "//" + window.location.host;

moment.tz.setDefault('Europe/Rome')
Vue.prototype.$moment = moment

Vue.config.silent = false;
Vue.config.performance = true
Vue.config.productionTip = true

let baseURL = process.env.VUE_APP_BASEURL;
let axios = axiosApi.create({
    baseURL: baseURL,
});

console.log(baseURL)
console.log(baseURL)
console.log(baseURL)
console.log(baseURL)

window.axios = axios;

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

let tokenFromUrl = getParameterByName('token')
if (tokenFromUrl !== null && typeof tokenFromUrl === 'string') {
    localStorage.setItem('token', tokenFromUrl)
    window.location.replace('https://dashboard.funer24.com/#/')
}


if (localStorage.getItem('token') !== null) {
    window.axios.interceptors.request.use(function (config) {
        config.headers.common = {
            Authorization: 'Token ' + localStorage.getItem('token'),
            "Content-Type": "application/json",
            Accept: "application/json"
        }
        return config;
    })
} else {
    window.location.replace(host + "/#/pages/login");
}

Vue.use(CoreuiVuePro)
Vue.component('v-select', vSelect)

Vue.use(Geocoder, {
    defaultCountryCode: null,
    defaultLanguage: null,
    defaultMode: 'address',
    googleMapsApiKey: process.env.VUE_APP_GOOGLEMAPS_KEY
});


Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLEMAPS_KEY,
        libraries: 'places',
    },
    installComponents: true
})

new Vue({
    el: '#app',
    router,
    store,
    icons,
    i18n,
    mounted() {
        store.dispatch('agenzia/get')
    },
    template: '<App/>',
    components: {
        App
    }
})

