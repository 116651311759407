import common from './common'

const CREATE = '/dashboard/api/v0/azienda/cellulari/create';
const LIST = '/dashboard/api/v0/azienda/cellulari/view';
const UPDATE = '/dashboard/api/v0/azienda/cellulari/update/';
const DESTROY = '/dashboard/api/v0/azienda/cellulari/destroy/';

export default {

    namespaced: true,
    state: {
        cellulari: null
    },

    actions: {

        list({commit}) {
            return axios
                .get(LIST)
                .then((response) => {
                    if (response.status === 200) {
                        commit('SET', response.data.results)
                        return true;
                    }
                }, (e) => {
                    common.logError("list", e)
                    return e.response.data;
                });
        },

        create({commit}) {
            axios
                .post(CREATE, {})
                .then((response) => {
                    console.log(response)
                    if (response.status === 201) {
                        console.log(response.data)
                        commit('CREATE', response.data)
                        return true;
                    }
                }, (e) => {
                    common.logError("create", e)
                    return e.response.data;
                });
        },

        destroy({commit}, item) {
            return axios
                .delete(DESTROY + item.id)
                .then((response) => {
                    console.log(response)
                    if (response.status === 204) {
                        commit('REMOVE', item.id)
                        return true;
                    }
                }, (e) => {
                    common.logError("destroy", e)
                    return e.response.data;
                });
        },

        update({commit}, item) {
            return axios
                .patch(UPDATE + item.id, item)
                .then((response) => {
                    console.log(response)
                    return true;
                }, (e) => {
                    common.logError("update", e)
                    return e.response.data;
                });
        },
    },

    mutations: {
        REMOVE(state, id) {
            state.cellulari = state.cellulari.filter((item) => item.id !== id)
        },
        CREATE(state, item) {
            state.cellulari.unshift(item)
        },
        SET(state, result) {
            state.cellulari = result
        },
    },

    getters: {
        cellulari: (state) => state.cellulari,
    }
}
