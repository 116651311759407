import common from './common'
const CREATE = '/dashboard/api/v0/azienda/sedi/create';
const LIST = '/dashboard/api/v0/azienda/sedi/view';
const UPDATE = '/dashboard/api/v0/azienda/sedi/update/';
const DESTROY = '/dashboard/api/v0/azienda/sedi/destroy/';

export default {

    namespaced: true,
    state: {
        sedi: null
    },

    actions: {

        list({commit}) {
            return axios
                .get(LIST)
                .then((response) => {
                    if (response.status === 200) {
                        commit('SET', response.data.results)
                        return true;
                    }
                }, (e) => {
                    common.logError("list", e)
                    return e.response.data;
                });
        },

        create({commit}) {
            return axios
                .post(CREATE, {})
                .then((response) => {
                    if (response.status === 201) {
                        console.log(response.data)
                        commit('CREATE', response.data)
                        return true;
                    }
                }, (e) => {
                    common.logError("create", e)
                    return e.response.data;
                });
        },

        destroy({commit}, sede) {
            return axios
                .delete(DESTROY + sede.id)
                .then((response) => {
                    console.log(response)
                    if (response.status === 204) {
                        commit('REMOVE', sede.id)
                        return true;
                    }
                }, (e) => {
                    common.logError("destroy", e)
                    return e.response.data;
                });
        },

        update({commit}, sede) {

            let comuni = null;
            if (sede.comuni !== null) {
                if (sede.comuni.ref_id !== undefined) {
                    comuni = parseInt(sede.comuni.ref_id);
                } else {
                    comuni = parseInt(sede.comuni.id);
                }
            }

            return axios
                .patch(
                    UPDATE + sede.id,
                    {
                        comuni: comuni,
                        agenzie: sede.agenzie.id,
                        telefono: sede.telefono,
                        address: sede.address,
                        via: sede.address,
                    })
                .then((response) => {

                    if (response.status === 200) {
                        console.log("UPDATED", response.data)
                        return true;
                    }
                }, (e) => {
                    common.logError("update", e)
                    return e.response.data;
                });
        },
    },

    mutations: {
        REMOVE(state, id) {
            state.sedi = state.sedi.filter((item) => item.id !== id)
        },
        CREATE(state, item) {
            state.sedi.unshift(item)
        },
        SET(state, result) {
            state.sedi = result
        },
    },

    getters: {
        sedi: (state) => state.sedi,
    }
}
