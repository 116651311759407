import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import stanzafuneraria from './stores/modules/stanzafuneraria';
import casafuneraria from './stores/modules/casafuneraria';
import agenzia from './stores/modules/agenzia';
import faq from './stores/modules/faq';
import cellulari from './stores/modules/cellulari';
import cellularisedi from './stores/modules/cellularisedi';
import sedi from './stores/modules/sedi';
import upload from './stores/modules/upload';


export default new Vuex.Store({
    state: {
        sidebarShow: 'responsive',
        sidebarMinimize: false,
        asideShow: false,
        darkMode: false,
        isAuthenticated: false,
    },
    mutations: {

        toggleSidebarDesktop(state) {
            const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
            state.sidebarShow = sidebarOpened ? false : 'responsive'
        },
        toggleSidebarMobile(state) {
            const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
            state.sidebarShow = sidebarClosed ? true : 'responsive'
        },
        set(state, [variable, value]) {
            state[variable] = value
        },
        toggle(state, variable) {
            state[variable] = !state[variable]
        }
    },
    modules: {
        agenzia,
        stanzafuneraria,
        casafuneraria,
        faq,
        cellulari,
        cellularisedi,
        sedi,
        upload
    }
})
